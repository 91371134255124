import React, {Component} from 'react'
import axios from 'axios'
import Grid from '@material-ui/core/Grid';
import { FormGroup, Typography, FormControlLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import CheckboxField from '@material-ui/core/Checkbox'
import {withStyles} from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { TimePicker } from "@material-ui/pickers";
import Checkbox from '@material-ui/core/Checkbox'



const useStyles = theme => ({
  mainContainer: {
    marginTop: "5em",
    marginLeft: "10em",
    [theme.breakpoints.down("md")]: {
      marginTop: "3em"
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "2em"
    }
  }
})

class ClassForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          name: '',
          custom_field: {
            cf_course: this.props.location.course_id,
            cf_fee: 1000,
            cf_start_date: new Date(),
            cf_end_date: new Date(),
            cf_start_time: "",
            cf_end_time: "",
            cf_days_in_week: "Friday;Monday;Wednesday",
            cf_max_seats: 20,
            cf_active: "No",
            cf_days_in_week: {
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
              sunday: false
            }
          },
        }
    
        this.handleOnChange = this.handleOnChange.bind(this);
      }


    handleOnChange = (event) => {
        const target = event.target
        const value = target.value
        this.setState({
            name: value
        })
    }

    handleCFOnChange = (event) => {
      const target = event.target
      const value = target.value
      const name = target.name
      console.log(target.value)
      this.setState((prevState) => ({
        name: prevState.name,
        custom_field: {
          ...prevState.custom_field,
          [name]: value
        }
      }))

    }


    handleDateChange = (name, date) => {
      this.setState((prevState) => ({
        name: prevState.name,
        custom_field: {
          ...prevState.custom_field,
          [name]: date
        }
      }))
    }

    onConfirm = () => {
      axios({
        method: 'post',
        url: 'https://us-central1-scadoosh-qa.cloudfunctions.net/createClassRecordInCRM',
        data: this.state,
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        console.log(response)
        this.setState({
          name: '',
          custom_field: {
            cf_course: this.props.location.course_id,
            cf_fee: 1000,
            cf_start_date: new Date(),
            cf_end_date: new Date(),
            cf_start_time: "",
            cf_end_time: "",
            cf_days_in_week: "",
            cf_max_seats: 20,
            cf_active: "No",
            cf_days_in_week: {
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
              sunday: false
            }
          },
        })
      })
      .catch((error) => {
        console.log(error);
      })
      alert("Your class has been successfully created, submit more classes if you have, otherwise you may close the window! Thank you!")
    }

    handleCheck = (event) => {
      const target = event.target
      const checked = target.checked
      const name = target.name
      this.setState((prevState) => ({
        name: prevState.name,
        custom_field: {
          ...prevState.custom_field,
          cf_days_in_week : {
            ...prevState.custom_field.cf_days_in_week,
            [name]: !prevState.custom_field.cf_days_in_week[name]
          }
        }
      }))

    }


    weekDay = [{id:0, day: "Sunday"},{id:1, day: "Monday"},{id:2, day: "Tuesday"},{id:3, day: "Wednesday"},{id:4, day: "Thursday"},{id:5, day: "Friday"},{id:6, day: "Saturday"}]


    render(){
      const { classes } = this.props
        return(
       <Grid container direction="column" className={classes.mainContainer}>
          <Grid container direction="row">
            <Grid item container direction="column" justify="center">
              <Grid item>
                <Typography variant="h2">Add Classes For {this.props.location.course_title} (Step 2 of 2)</Typography> 
                <Typography variant="h4">Please enter the class details below. Classes are nothing but schedule of your Course</Typography> 
              </Grid>
              <Grid item container style={{maxWidth: '30em'}}>
              <Grid item style={{paddingBottom: '10px'}}>
                  <TextField
                  name="course_id" 
                  disabled
                  id="course_id"
                  label="Course Id"
                  variant="outlined"
                  value={this.props.location.course_id}
                  style={{width: "25ch"}}
                  inputProps={{
                    maxLength: 30
                  }}
                  />
                </Grid>

                <Grid item style={{paddingBottom: '10px'}}>
                  <TextField
                  name="class_name" 
                  required
                  id="name"
                  placeholder="Eg., Weekend, Weekday-1, Weekend-2, Mid Week"
                  label="Class Name"
                  variant="outlined"
                  value={this.state.name}
                  style={{width: "25ch"}}
                  onChange={this.handleOnChange}
                  inputProps={{
                    maxLength: 45
                  }}
                  />
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    fullWidth
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    id="class-start-date"
                    name="cf_start_date"
                    label="Class Start Date *"
                    value={this.state.custom_field.cf_start_date}
                    onChange={this.handleDateChange.bind(this, "cf_start_date")}
                    KeyboardButtonProps = {{
                        'aria-label': 'change date',
                    }}
                    style={{width: 250}}
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    fullWidth
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    id="class-end-date"
                    label="Class End Date *"
                    name="cf_end_date"
                    value={this.state.custom_field.cf_end_date}
                    onChange={this.handleDateChange.bind(this, "cf_end_date")}
                    KeyboardButtonProps = {{
                        'aria-label': 'change date',
                    }}
                    style={{width: 250, paddingBottom: '20px'}}
                    />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item style={{paddingBottom: '10px'}}>
                  <TextField
                  name="cf_start_time" 
                  id="cf_start_time"
                  placeholder="7:00 AM"
                  helperText="Enter time in 8:00 AM format"
                  label="Class Start Time *"
                  variant="outlined"
                  value={this.state.custom_field.cf_start_time}
                  style={{width: "25ch"}}
                  onChange={this.handleCFOnChange}
                  inputProps={{
                    maxLength: 30
                  }}
                  />
                </Grid>
                <Grid item style={{paddingBottom: '10px'}}>
                  <TextField
                  name="cf_end_time"
                  id="cf_end_time"
                  placeholder="8:00 AM"
                  helperText="Enter time in 8:00 AM format"
                  label="Class End Time *"
                  variant="outlined"
                  value={this.state.custom_field.cf_end_time}
                  style={{width: "25ch"}}
                  onChange={this.handleCFOnChange}
                  inputProps={{
                    maxLength: 30
                  }}
                  />
                </Grid>
                <Grid item>
                  <Typography color="primary">Days In Week *</Typography>
                  <FormGroup row>
                  <FormControlLabel
                      control={<Checkbox 
                        checked={this.state.custom_field.cf_days_in_week.monday}
                        color="primary"
                        onChange={this.handleCheck}
                        name="monday" />}
                        label="Monday"
                    />
                  <FormControlLabel
                      control={<Checkbox 
                        checked={this.state.custom_field.cf_days_in_week.tuesday}
                        color="primary"
                        onChange={this.handleCheck}
                        name="tuesday" />}
                        label="Tuesday"
                    />
                  <FormControlLabel
                      control={<Checkbox 
                        checked={this.state.custom_field.cf_days_in_week.wednesday}
                        color="primary"
                        onChange={this.handleCheck}
                        name="wednesday" />}
                        label="Wednesday"
                    />
                  <FormControlLabel
                      control={<Checkbox 
                        checked={this.state.custom_field.cf_days_in_week.thursday}
                        color="primary"
                        onChange={this.handleCheck}
                        name="thursday" />}
                        label="Thursday"
                    />
                  <FormControlLabel
                      control={<Checkbox 
                        checked={this.state.custom_field.cf_days_in_week.friday}
                        color="primary"
                        onChange={this.handleCheck}
                        name="friday" />}
                        label="Friday"
                    />
                  <FormControlLabel
                      control={<Checkbox 
                        checked={this.state.custom_field.cf_days_in_week.saturday}
                        color="primary"
                        onChange={this.handleCheck}
                        name="saturday" />}
                        label="Saturday"
                    />
                  <FormControlLabel
                      control={<Checkbox 
                        checked={this.state.custom_field.cf_days_in_week.sunday}
                        color="primary"
                        onChange={this.handleCheck}
                        name="sunday" />}
                        label="Sunday"
                    />
                  </FormGroup>
                </Grid>
                <Grid item style={{paddingBottom: '10px'}}>
                <TextField
                  name="cf_max_seats"
                  id="cf_max_seats"
                  helperText="Typically 20, unless agreed otherwise"
                  label="Max Seats *"
                  variant="outlined"
                  value={this.state.custom_field.cf_max_seats}
                  style={{width: "25ch"}}
                  onChange={this.handleCFOnChange}
                  inputProps={{
                    maxLength: 30
                  }}
                  />
                </Grid>
              <Grid item>
                <Button
                variant="contained"
                color="primary"
                style={{width: '45ch'}}
                onClick={this.onConfirm}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
      </Grid>
        )
    }

}

export default withStyles(useStyles)(ClassForm)