import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Link } from "@material-ui/core";

export default function PopoverPopupState() {
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
            <Typography>
                By Clicking Next I agree to all <Link
            variant="contained"
            color="primary"
            {...bindTrigger(popupState)}
          >
            Terms and Conditions
          </Link>

            </Typography>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <Box p={2}>
              <Typography>
                <p>
                  <b>Terms & Conditions:</b>
                  <ol>
                    <li>
                      The session should have minimum 1 student enrolled to
                      qualify for pay.
                    </li>
                    <li>
                      Class cancellations is not allowed at any cost. In case
                      you have some emergencies, we request you to arrange an
                      alternate teacher for the class.
                    </li>
                    <li>
                      Teacher must be logged in atleast 10 minutes before the
                      scheduled session time and keep all materials ready.
                    </li>
                    <li>
                      The fixed fee is subject to revision any time during the
                      contract. In case of any revision of fees, both party will
                      have to mutually agree and a revised contract will be
                      signed
                    </li>
                    <li>
                      Scadoosh Labs Pvt. Ltd. reserves the right to terminate
                      the contract at any time.
                    </li>
                    <li>
                      The live sessions maybe recorded and used for future
                      purposes within Scadoosh platform, if required
                    </li>
                  </ol>
                </p>
              </Typography>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
