import React, {Component} from 'react'
import axios from 'axios'
import Grid from '@material-ui/core/Grid';
import { FormControl, Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
// import background from '../assets/3837.jpg'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormLabel from '@material-ui/core/FormLabel'
import {withStyles} from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';


const useStyles = theme => ({
  mainContainer: {
    marginTop: "5em",
    marginLeft: "10em",
    [theme.breakpoints.down("md")]: {
      marginTop: "3em"
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "2em"
    }
  }
})

class SimpleForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          name: '',
          custom_field: {
            cf_description: '',
            cf_details: '',
            cf_items_required: '',
            cf_delivery_method: '',
            cf_institute_name: '',
            cf_teacher_name: '',
            cf_category: '',
            cf_sub_category: '',
            cf_from_grade: '',
            cf_to_grade: '',
            cf_level: '',
            cf_active: 'No',
            cf_poster_url: '',
            cf_promo_video_url: ''
          },
        }
    
        this.handleOnChange = this.handleOnChange.bind(this);
      }


    handleOnChange = (event) => {
        const target = event.target
        const value = target.value
        this.setState({
            name: value
        })
    }

    handleCFOnChange = (event) => {
      const target = event.target
      const value = target.value
      const name = target.name
      this.setState((prevState) => ({
        name: prevState.name,
        custom_field: {
          ...prevState.custom_field,
          [name]: value
        }
      }))

    }

    handleSubmit = (event) => {
        console.log("form submit....")
        event.preventDefault()
        this.props.onAddItem(this.state)
        this.setState({
            todo: '',
            dueDate: ''
        })
    }

    onConfirm = () => {
      axios({
        method: 'post',
        url: 'https://us-central1-scadoosh-qa.cloudfunctions.net/createCourseRecordInCRM',
        data: this.state,
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        console.log(response)
        this.setState({
          name: '',
          custom_field: {
            cf_description: '',
            cf_details: '',
            cf_items_required: '',
            cf_delivery_method: '',
            cf_institute_name: '',
            cf_teacher_name: '',
            cf_category: '',
            cf_sub_category: '',
            cf_from_grade: '',
            cf_to_grade: '',
            cf_level: '',
            cf_active: 'No',
            cf_poster_url: '',
            cf_promo_video_url: ''            
          }
        })
      })
      .catch((error) => {
        console.log(error);
      })
    }


    render(){
      // const styles = {
      //   root: {
      //     flexGrow: 1
      //   },
      //   background: {
      //     backgroundImage: `url(${background})`,
      //     backgroundPosition: "center",
      //     backgroundSize: "cover",
      //     backgroundRepeat: "no-repeat",
      //     height: "60em",
      //     paddingBottom: "10em",
      //     // [theme.breakpoints.down("md")]: {
      //     //   backgroundImage: `url(${mobileBackground})`
      //     // }
      //   }    
      // };
      const { classes } = this.props
        return(
       <Grid container direction="column" className={classes.mainContainer}>
          <Grid container direction="row">
            <Grid item container direction="column" justify="center">
              <Grid item container style={{maxWidth: '30em'}}>
              <ValidatorForm
                ref="form"
                onSubmit={this.handleCFOnChange}
               >
                <TextValidator
                    label="Email"
                    onChange={this.handleOnChange}
                    name="email"
                    value={this.state.name}
                    validators={['required', 'isEmail']}
                    errorMessages={['this field is required', 'email is not valid']}
                /> 
                <Button type="submit">Submit</Button>
               </ValidatorForm>
              </Grid>
            </Grid>
          </Grid>
      </Grid>
        )
    }

}

export default withStyles(useStyles)(SimpleForm)