import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
// import Button from '@material-ui/core/Button'
// import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { useTheme } from "@material-ui/core/styles";
// import logo from '../../assets/SVG/Asset.svg'
// import Tabs from '@material-ui/core/Tabs'
// import Tab from '@material-ui/core/Tab'

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

const useStyles = makeStyles( theme => ({
    toobarMargin: {
        ...theme.mixins.toolbar,
        marginBottom: "3em"
    },
    logo: {
        height: "7em"
    },
    tabContainer: {
        marginLeft: "auto"
    },
    tab: {
        ...theme.typography.tab,
        minWidth: 10,
        marginLeft: "25px"
    }
}))

export default function Header(){
    const classes = useStyles()
    const theme = useTheme()
return(
    <React.Fragment>
        <ElevationScroll>
            <AppBar position="fixed">
                <Toolbar disableGutters>
                    {/* <img alt="logo" className={classes.logo} src={logo} /> */}

                    <Typography variant="h3">
                    Scadoosh Teacher
                    </Typography>

                    {/* <Tabs className={classes.tabContainer}>
                        <Tab className={classes.tab} label="Home" value={0}/>
                        <Tab className={classes.tab} label="About Us" value={1}/>
                        <Tab className={classes.tab} label="Login" value={2}/>
                        <Tab className={classes.tab} label="Onboard" value={3}/>
                    </Tabs> */}
                </Toolbar>
            </AppBar>    
        </ElevationScroll>
        <div className={classes.toobarMargin} />
    </React.Fragment>
)
}
