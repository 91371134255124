import { createMuiTheme } from '@material-ui/core/styles';

const mintGreen = "#40CB90"
const grey600 = "#D2D2D2"
const white = "#FFFFFF"

export default createMuiTheme({
    palette:{
        common: {
            green: `${mintGreen}`,
            grey: `${grey600}`,
            white: `${white}`
        },
        primary: {
            main: `${mintGreen}`
        },
        secondary: {
            main: `${grey600}`
        }  
    },
    typography: {
        tab: {
            fontFamily: "Raleway",
            fontWeight: 600,
            textTransform: false,
            color: "white",
            fontSize: "1rem",            
        },
        h4: {
            fontFamily: "Raleway",
            fontWeight: 500,
            fontSize: "1.5rem",
            color: grey600,
            lineHeight: 1.5
          },
          h3: {
            fontFamily: "Raleway",
            fontWeight: 500,
            fontSize: "2.5rem",
            color: white,
            lineHeight: 1.5
          },
        h2: {
            fontFamily: "Raleway",
            fontWeight: 700,
            fontSize: "2.5rem",
            color: grey600,
            lineHeight: 1.5
          },
    },
    overrides:{
        MuiInputLabel: {
            root: {
                color: `${mintGreen}`
            }
        },
        MuiInput: {

        }
    }
});