import React, {Component} from 'react'
import axios from 'axios'
import Grid from '@material-ui/core/Grid';
import { FormControl, Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {withStyles} from '@material-ui/core/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import history from './History'
import {context, Redirect} from 'react-router-dom'
import PopoverPopupState from './TermsAndCondition'

const useStyles = theme => ({
  mainContainer: {
    marginTop: "5em",
    marginLeft: "10em",
    [theme.breakpoints.down("md")]: {
      marginTop: "3em"
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "2em"
    }
  }
})

class AddItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
          name: '',
          custom_field: {
            cf_description: '',
            cf_details: '',
            cf_items_required: '',
            cf_delivery_method: '',
            cf_institute_name: '',
            cf_teacher_name: '',
            cf_category: '',
            cf_sub_category: '',
            cf_from_grade: '',
            cf_to_grade: '',
            cf_level: '',
            cf_active: 'No',
            cf_poster_url: '',
            cf_promo_video_url: ''
          },
        }
    
        this.handleOnChange = this.handleOnChange.bind(this);
      }


    handleOnChange = (event) => {
        const target = event.target
        const value = target.value
        console.log(value)
        this.setState({
            name: value
        })

    }

    handleCFOnChange = (event) => {
      const target = event.target
      let value = target.value
      const name = target.name
      if(name === "cf_institute_name" || name === "cf_teacher_name"){
        value = value.trim()
      }
      this.setState((prevState) => ({
        name: prevState.name,
        custom_field: {
          ...prevState.custom_field,
          [name]: value
        }
      }))
    }

    handleSubmit = (event) => {
        console.log("form submit....")
        event.preventDefault()
        this.props.onAddItem(this.state)
        this.setState({
            todo: '',
            dueDate: ''
        })
    }

    onConfirm = () => {
      const data = axios({
        method: 'post',
        url: 'https://us-central1-scadoosh-qa.cloudfunctions.net/createCourseRecordInCRM',
        data: this.state,
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        this.setState({
          name: '',
          custom_field: {
            cf_description: '',
            cf_details: '',
            cf_items_required: '',
            cf_delivery_method: '',
            cf_institute_name: '',
            cf_teacher_name: '',
            cf_category: '',
            cf_sub_category: '',
            cf_from_grade: '',
            cf_to_grade: '',
            cf_level: '',
            cf_active: 'No',
            cf_poster_url: '',
            cf_promo_video_url: ''            
          }
        })
        return response.data
      })
      .catch((error) => {
        console.log(error);
      })
      data.then(d => {
        console.log(d)
        history.push({pathname: '/addClasses', course_id: d.cm_course.id, course_title: d.cm_course.name})
      })
      
    }


    render(){
      const { classes } = this.props
        return(
       <Grid container direction="column" className={classes.mainContainer}>
          <Grid container direction="row">
            <Grid item container direction="column" justify="center">
              <Grid item>
                <Typography variant="h2">Create Your Course On Scadoosh (Step 1 of 2)</Typography> 
                <Typography variant="h4">Please enter the course details below</Typography> 
              </Grid>
              <ValidatorForm
                ref="form"
                onSubmit={this.onConfirm}
               >
              <Grid item style={{paddingBottom: '10px'}}>
                  <TextValidator
                  id="institute-name"
                  name="cf_institute_name"
                  label="Institute Id *"
                  placeholder="Refer your confirmation email"
                  variant="outlined"
                  value={this.state.custom_field.cf_institute_name}
                  onChange={this.handleCFOnChange}
                  style={{width: "35ch"}}
                  validators={['required']}
                  errorMessages={['Please fill out this field',]}
                  />
                </Grid>
                <Grid item style={{paddingBottom: '10px'}}>
                  <TextValidator
                  id="teacher-name"
                  name="cf_teacher_name"
                  label="Teacher Id *"
                  placeholder="Refer your confirmation email"
                  variant="outlined"
                  value={this.state.custom_field.cf_teacher_name}
                  onChange={this.handleCFOnChange}
                  style={{width: "35ch"}}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  />    
                </Grid>
              <Grid item container style={{maxWidth: '30em'}}>
                <Grid item style={{paddingBottom: '10px'}}>
                  <TextValidator
                  name="name" 
                  id="name"
                  label="Title of the Course *"
                  variant="outlined"
                  value={this.state.name}
                  style={{width: "25ch"}}
                  onChange={this.handleOnChange}
                  inputProps={{
                    maxLength: 30
                  }}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  />
                </Grid>
                <Grid item style={{paddingBottom: '10px'}}>
                  <TextValidator
                  id="cf_description"
                  name="cf_description"
                  label="Brief Course Description *"
                  variant="outlined"
                  value={this.state.custom_field.cf_description}
                  onChange={this.handleCFOnChange}
                  inputProps={{
                    maxLength: 50
                  }}
                  style={{width: "40ch"}}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  />
                </Grid>
                <Grid item style={{paddingBottom: '10px'}}>
                <TextValidator
                  id="cf_details"
                  name="cf_details"
                  label="Detailed Course Description *"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={this.state.custom_field.cf_details}
                  onChange={this.handleCFOnChange}
                  inputProps={{
                    maxLength: 200
                  }}
                  style={{width: "45ch"}}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
                </Grid>
                <Grid item style={{paddingBottom: '10px'}}>
                <TextValidator
                  id="items-required"
                  name="cf_items_required"
                  label="Items Required *"
                  multiline
                  rows={3}
                  variant="outlined"
                  value={this.state.custom_field.cf_items_required}
                  onChange={this.handleCFOnChange}
                  inputProps={{
                    maxLength: 100
                  }}
                  style={{width: "45ch"}}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
                </Grid>
                <Grid item style={{paddingBottom: '10px'}}>
                  <FormControl variant="outlined" style={{minWidth: 240}}>
                  <InputLabel id="delivery-method">Course Delivery Method *</InputLabel>
                  <Select
                    labelId="cf_delivery_method"
                    required
                    name="cf_delivery_method"
                    id="cf_delivery_method"
                    label="Course Delivery Method"
                    value={this.state.custom_field.cf_delivery_method}
                    onChange={this.handleCFOnChange}
                  >
                    <MenuItem value="Online">Online</MenuItem>
                    <MenuItem value="In-Studio">In-Studio</MenuItem>
                  </Select>
                </FormControl>
                </Grid>
                <Grid item style={{ paddingBottom: '10px'}}>
                  <FormControl variant="outlined" style={{minWidth: 170}}>
                  <InputLabel id="category-label">Course Category *</InputLabel>
                  <Select
                    labelId="category"
                    required
                    name="cf_category"
                    id="category"
                    label="Category"
                    value={this.state.custom_field.cf_category}
                    onChange={this.handleCFOnChange}
                  >
                    <MenuItem value='Art'>Art</MenuItem>
                    <MenuItem value='Science'>Science</MenuItem>
                    <MenuItem value='Fitness'>Fitness</MenuItem>
                    <MenuItem value='Technology'>Technology</MenuItem>
                    <MenuItem value='Academics'>Academics</MenuItem>
                    <MenuItem value='Music'>Music</MenuItem>
                    <MenuItem value='LifeSkills'>Life Skills</MenuItem>
                    <MenuItem value='Other'>Others</MenuItem>
                  </Select>
                </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="outlined" style={{minWidth: 135}}>
                  <InputLabel id="from-grade-label">From Grade *</InputLabel>
                  <Select
                    labelId="from-grade"
                    name="cf_from_grade"
                    required
                    id="from-grade"
                    label="Grade"
                    value={this.state.custom_field.cf_from_grade}
                    onChange={this.handleCFOnChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>Grade 1</MenuItem>
                    <MenuItem value={2}>Grade 2</MenuItem>
                    <MenuItem value={3}>Grade 3</MenuItem>
                    <MenuItem value={4}>Grade 4</MenuItem>
                    <MenuItem value={5}>Grade 5</MenuItem>
                    <MenuItem value={6}>Grade 6</MenuItem>
                    <MenuItem value={7}>Grade 7</MenuItem>
                    <MenuItem value={8}>Grade 8</MenuItem>
                    <MenuItem value={9}>Grade 9</MenuItem>
                    <MenuItem value={10}>Grade 10</MenuItem>
                    <MenuItem value={11}>Grade 11</MenuItem>
                    <MenuItem value={12}>Grade 12</MenuItem>
                  </Select>
                </FormControl>
                </Grid>
                <Grid item style={{paddingLeft: '10px', paddingBottom: '10px'}}>
                  <FormControl variant="outlined" style={{minWidth: 120}}>
                  <InputLabel id="to-grade-label">To Grade *</InputLabel>
                  <Select
                    labelId="to-grade"
                    name="cf_to_grade"
                    required
                    id="to-grade"
                    label="Grade"
                    value={this.state.custom_field.cf_to_grade}
                    onChange={this.handleCFOnChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>Grade 1</MenuItem>
                    <MenuItem value={2}>Grade 2</MenuItem>
                    <MenuItem value={3}>Grade 3</MenuItem>
                    <MenuItem value={4}>Grade 4</MenuItem>
                    <MenuItem value={5}>Grade 5</MenuItem>
                    <MenuItem value={6}>Grade 6</MenuItem>
                    <MenuItem value={7}>Grade 7</MenuItem>
                    <MenuItem value={8}>Grade 8</MenuItem>
                    <MenuItem value={9}>Grade 9</MenuItem>
                    <MenuItem value={10}>Grade 10</MenuItem>
                    <MenuItem value={11}>Grade 11</MenuItem>
                    <MenuItem value={12}>Grade 12</MenuItem>
                  </Select>
                </FormControl>
                </Grid>
              </Grid>
              <Grid item style={{ paddingBottom: '10px'}}>
                  <FormControl variant="outlined" style={{minWidth: 150}}>
                  <InputLabel id="course-level">Course Level *</InputLabel>
                  <Select
                    labelId="level"
                    name="cf_level"
                    required
                    id="level"
                    label="Course Level"
                    value={this.state.custom_field.cf_level}
                    onChange={this.handleCFOnChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>Beginner</MenuItem>
                    <MenuItem value={2}>Intermediate</MenuItem>
                    <MenuItem value={3}>Expert</MenuItem>
                  </Select>
                </FormControl>
                </Grid>
                <Grid item style={{paddingBottom: '10px'}}>
                  <TextValidator
                  id="cf_promo_video_url"
                  name="cf_promo_video_url"
                  label="Promo Video URL"
                  variant="outlined"
                  value={this.state.custom_field.cf_promo_video_url}
                  onChange={this.handleCFOnChange}
                  inputProps={{
                    maxLength: 1000
                  }}
                  style={{width: "35ch"}}/>    
                </Grid>
                <Grid item style={{paddingBottom: '10px'}}>
                  <TextValidator
                  id="cf_poster_url"
                  name="cf_poster_url"
                  label="Poster URL"
                  variant="outlined"
                  value={this.state.custom_field.cf_poster_url}
                  onChange={this.handleCFOnChange}
                  inputProps={{
                    maxLength: 1000
                  }}
                  style={{width: "35ch"}}
                  />    
                </Grid>
                <Grid item style={{paddingTop: '10px', paddingBottom: '20px'}}>
                < PopoverPopupState />
                </Grid>
              <Grid item>
                <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{width: '45ch'}}>
                  Next
                </Button>
              </Grid>
               </ValidatorForm>
            </Grid>
          </Grid>
      </Grid>
        )
    }

}

export default withStyles(useStyles)(AddItem)