import React from 'react';
import './App.css';
import 'fontsource-roboto';
import Header from './components/ui/Header'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import theme from './components/ui/Themes'
import AddItem from './components/CourseForm'
import ClassForm from './components/ClassForm'
import SimpleForm from './components/SimpleForm'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import history from './components/History'
import {Router, Route, Switch } from "react-router-dom";


// const useStyles = makeStyles(theme => ({
//   mainContainer: {
//     marginTop: "5em",
//     marginLeft: "10em",
//     [theme.breakpoints.down("md")]: {
//       marginTop: "3em"
//     },
//     [theme.breakpoints.down("xs")]: {
//       marginTop: "2em"
//     }
//   }
// }))

function App() {
  // const classes = useStyles()
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  // const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
          <Route
          exact 
          path="/"
          render={props => (
            <AddItem
            {...props}
            />
          )}
          />
          <Route
          exact 
          path="/addClasses"
          render={props => (
            <ClassForm
            {...props}
            />
          )}
          />
          <Route
          exact 
          path="/simpleForm"
          render={props => (
            <SimpleForm
            {...props}
            />
          )}
          />          
      <Header />
      </Router>
    </ThemeProvider>
  );
}

export default App;
